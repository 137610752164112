<template>
  <div>
    <b-row v-if="isLoading">
      <b-col class="d-flex justify-content-center mt-4">
        <div class="text-center mt-4">
          <b-spinner
            variant="primary"
            class="mr-1"
          />
          <p class="mt-1">
            Memuat Data Sertifikat
          </p>
        </div>
      </b-col>
    </b-row>
    <b-card
      v-else
      class="card mb-1"
      title="Detail Sertifikat"
    >
      <object
        :data="`${process.env.VUE_APP_BASE_API_URL}/training-certificates/${$route.params.certificateId}`"
        alt=""
        srcset=""
        class="w-100 mb-1"
      >
      </object>
      <b-button
        variant="primary"
        class="float-right"
        @click="downloadFile()"
      >
        Download
      </b-button>
    </b-card>
  </div>
</template>

<script>
// Components
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BAvatar,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BFormInvalidFeedback,
  BSpinner,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormText,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

// Utils
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { reqGetCertificate } from '@/api/user/certificate'

export default {
  components: {
    BFormInvalidFeedback,
    BRow,
    BCol,
    BCard,
    BButton,
    BAvatar,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BSpinner,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormText,
    vSelect,
    flatPickr,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      isLoading: false,
      data: {}
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.showPassword ? 'EyeOffIcon' : 'EyeIcon'
    },
  },
  mounted() {
    this.loadCertificate()
  },
  methods: {
    downloadFile() {
      window.location.href = this.data.file_download_url
      this.$toast({
        component: ToastificationContent,
        props: {
          title: 'Berhasil',
          icon: 'CheckIcon',
          text: 'Sertifikat sedang didownload',
          variant: 'success',
        },
      })
    },
    showToast(variant, icon, title, message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          text: message,
          variant,
        },
      })
    },
    validateForm() {
      const isValid = true

      return isValid
    },
    async loadCertificate() {
      await reqGetCertificate(this.$route.params.certificateId)
        .then(response => {
          this.data = response.data.data
          this.isLoading = false
        })
        .catch(error => {
          this.showToast(
            'danger',
            'AlertTriangleIcon',
            'Gagal',
            error.response.data.message || 'Data tidak ditemukan',
          )
          this.isLoading = false
          this.$router.push({
            name: 'error-404',
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";

.line-height-small {
  line-height: 1.5rem;
}

.small-gap-right {
  margin-right: 8px;
}

.small-gap-top {
  margin-top: 8px;
}

.small-gap-bottom {
  margin-bottom: 8px;
}

.regular-icon {
  top: 0 !important;
  font-size: 1.2rem;
}

.card-icon {
  top: 0 !important;
  font-size: 1.5rem;
}

.card-icon-lg {
  top: 0 !important;
  font-size: 2.5rem;
}

.pointer {
  cursor: pointer;
}

.is-invalid {
  border-color: #ea5455 !important;
}

.w-30 {
  width: 30% !important;
}

.w-70 {
  width: 70% !important;
}
</style>
